* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  margin: 0;
  padding: 0;
}

h1 {
  font-family: 'Bree Serif', serif;
  font-weight: 100;
  margin: 0;
}

h3 {
  margin: 0;
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
