header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  color: #242d2f;
  text-shadow: 1px 1px #fff;
  padding: 0 8px;
}